<template>
    <div>
        <nl-loader v-if="preloading" :show="true" />

        <div v-else>
            <b-loading v-model="isLoading" :is-full-page="true" />

            <section class="box">
                <div v-if="!advisor" class="mb-2 buttons">
                    <a href="/admin/campaigns/new" class="button is-warning text-decoration-none">
                        <i class="fas fa-plus-circle mr-2" />
                        Nouvel accompagnement
                    </a>

                    <button class="button is-warning" @click="$refs.exportModal.openModal()">Export</button>
                    <button class="button is-warning" @click="openModal">
                        <i class="fas fa-file-invoice-dollar mr-2" />
                        Lancer l'appel à crédits
                    </button>
                    <router-link to="/admin/rank" class="button is-warning">
                        <i class="fas fa-chart-line mr-2" />
                        Rank
                    </router-link>
                </div>

                <b-tabs v-if="tabs.length > 0" v-model="activeTab">
                    <b-tab-item
                        v-for="(tab, index) in tabs"
                        :key="`tab_${index}`"
                        :label="tab.label"
                        :icon="tab.icon"
                        :value="tab.type"
                        :disabled="tab.disabled"
                    >
                        <AdminAccompagnementTable
                            :url="tab.url"
                            :alert="tab.alert"
                            :type="tab.type"
                            :advisors="advisors"
                            :default-columns="
                                advisor
                                    ? ['mail_recap', 'launching_mail', 'credits']
                                    : ['budget_offsite', 'budget_onsite', 'credits']
                            "
                        />
                    </b-tab-item>
                </b-tabs>
            </section>
        </div>
        <ExportModal ref="exportModal" url="/admin/accompagnements/export" />
        <CampaignCreditModal ref="campaignCreditModal" @ordersCreated="getData" />
    </div>
</template>

<script>
    import ExportModal from '@/components/PartnerOrders/PartnerOrdersExportModal.vue'
    import CampaignCreditModal from '@/components/Campaigns/CampaignCreditModal.vue'
    import router from '@/router'

    export default {
        title: 'accompagnement.all',
        name: 'AdminAccompagnement',
        components: { CampaignCreditModal, ExportModal },
        props: {
            advisor: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                activeTab: 4,
                counts: [],
                tabs: [],
                advisors: [],

                statusCompleted: 'termine',
                statusWaitingValidation: 'a_confirmer',
                statusInProgress: 'in_progress',
                statusLastMonth: 'last_month',
                statusPrevMonths: 'prev_months',

                isLoading: false,
                preloading: false
            }
        },
        computed: {
            canLaunchCredits() {
                let today = new Date()
                let day = today.getDate()
                let month = today.getMonth()
                let year = today.getFullYear()
                let lastDayOfMonth = new Date(year, month + 1, 0).getDate()
                return day >= 24 && day <= lastDayOfMonth
            }
        },

        created() {
            this.getData()
        },
        methods: {
            router() {
                return router
            },
            getData: function () {
                this.preloading = true

                this.axios
                    .get(
                        this.advisor
                            ? '/admin/accompagnements/count?filter[assign_to_me]=1'
                            : '/admin/accompagnements/count'
                    )
                    .then(response => {
                        this.calculateTabData(response)
                        this.formatTabs()
                    })
                    .finally(() => (this.preloading = false))
            },
            openModal() {
                this.$refs.campaignCreditModal.show()
            },

            calculateTabData: function (response) {
                let counts = response.data.counts
                let countWaitingValidation = counts.find(key => key.statut === this.statusWaitingValidation)
                let countInProgress = counts.find(key => key.statut === this.statusInProgress)
                let countLastMonth = counts.find(key => key.statut === this.statusLastMonth)
                let countCompleted = counts.find(key => key.statut === this.statusCompleted)
                let countPrevMonths = counts.find(key => key.statut === this.statusPrevMonths)

                this.counts = {
                    waitingValidation: countWaitingValidation ? countWaitingValidation.total : 0,
                    inProgress: countInProgress ? countInProgress.total : 0,
                    lastMonth: countLastMonth ? countLastMonth.total : 0,
                    completed: countCompleted ? countCompleted.total : 0,
                    prev_months: countPrevMonths ? countPrevMonths.total : 0,
                    all: counts
                        .filter(
                            count =>
                                count.statut !== this.statusInProgress &&
                                count.statut !== this.statusLastMonth &&
                                count.statut !== this.statusPrevMonths
                        )
                        .reduce((a, b) => a + parseInt(b.total), 0)
                }

                this.advisors = response.data.advisors
            },

            formatTabs() {
                this.tabs = [
                    {
                        type: 'all',
                        label: this.$t('accompagnement.all') + ` (${this.counts.all})`,
                        icon: 'sitemap',
                        content_key: 'accompagnements',
                        disabled: false,
                        status: 'all',
                        url: `/admin/accompagnements?${this.advisor ? 'filter[assign_to_me]=1&' : ''}status=all`
                    },
                    {
                        type: 'accompagnementsWaitingValidation',
                        label: this.$t('accompagnement.status.a_confirmer') + ` (${this.counts.waitingValidation})`,
                        icon: 'clipboard-list',
                        content_key: 'accompagnementsWaitingValidation',
                        disabled: false,
                        status: this.statusWaitingValidation,
                        url:
                            `/admin/accompagnements?${this.advisor ? 'filter[assign_to_me]=1&' : ''}status=` +
                            this.statusWaitingValidation
                    },
                    {
                        type: 'accompagnementsPrevMonths',
                        label: 'Mois précèdents' + ` (${this.counts.prev_months})`,
                        icon: 'angle-double-left',
                        content_key: 'accompagnementsPrevMonths',
                        disabled: false,
                        status: this.statusPrevMonths,
                        url:
                            `/admin/accompagnements?${this.advisor ? 'filter[assign_to_me]=1&' : ''}status=` +
                            this.statusPrevMonths
                    },
                    {
                        type: 'accompagnementsLastMonth',
                        label: `Dernier mois (${this.capitalizeFirstLetter(
                            this.moment().subtract(1, 'month').format('MMMM')
                        )}) (${this.counts.lastMonth})`,
                        icon: 'spinner',
                        content_key: 'accompagnementsLastMonth',
                        disabled: false,
                        status: this.statusLastMonth,
                        url:
                            `/admin/accompagnements?${this.advisor ? 'filter[assign_to_me]=1&' : ''}status=` +
                            this.statusLastMonth
                    },
                    {
                        type: 'accompagnementsInProgress',
                        label: `Mois en cours (${this.capitalizeFirstLetter(this.moment().format('MMMM'))}) (${
                            this.counts.inProgress
                        })`,
                        icon: 'spinner',
                        content_key: 'accompagnementsInProgress',
                        disabled: false,
                        status: this.statusInProgress,
                        url:
                            `/admin/accompagnements?${this.advisor ? 'filter[assign_to_me]=1&' : ''}status=` +
                            this.statusInProgress
                    },
                    {
                        type: 'accompagnementsCompleted',
                        label: this.$t('accompagnement.status.termine') + ` (${this.counts.completed})`,
                        icon: 'clipboard-check',
                        content_key: 'accompagnementsCompleted',
                        disabled: false,
                        status: this.statusCompleted,
                        url:
                            `/admin/accompagnements?${this.advisor ? 'filter[assign_to_me]=1&' : ''}status=` +
                            this.statusCompleted
                    }
                ]
            },

            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1)
            },

            snackNotif(message, type = 'is-success', duration = 5000) {
                this.$buefy.snackbar.open({
                    message: message,
                    type: type,
                    position: 'is-bottom',
                    duration: duration
                })
            }
        }
    }
</script>